    /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        if($(window).width() >= '768') {
            $('#lecture-posts-container .row').each(function () {
                var maxheight = 0;
                $(this).find('.inner-container').each(function() {
                    maxheight = ($(this).find('.content-container').height() > maxheight ? $(this).find('.content-container').height() : maxheight);
                });
                $(this).find('.inner-container .content-container').css('height', maxheight+15);
            });
        }

        $('select').each(function () {

            // Cache the number of options
            var $this = $(this),
                numberOfOptions = $(this).children('option').length;

            // Hides the select element
            $this.addClass('s-hidden');

            // Wrap the select element in a div
            $this.wrap('<div class="select"></div>');

            // Insert a styled div to sit over the top of the hidden select element
            $this.after('<div class="styledSelect"></div>');

            // Cache the styled div
            var $styledSelect = $this.next('div.styledSelect');

            // Show the first select option in the styled div
            $styledSelect.text($this.children('option').eq(0).text());

            // Insert an unordered list after the styled div and also cache the list
            var $list = $('<ul />', {
                'class': 'options'
            }).hide().insertAfter($styledSelect);

            // Insert a list item into the unordered list for each select option
            var i = 0;
            if($('body').hasClass('page-template-template-contactus-php')) {
                i=1;
            }
            for (i = i; i < numberOfOptions; i++) {

                    if($this.children('option').eq(i).val() !== '') {
                        $('<li />', {
                            text: $this.children('option').eq(i).text(),
                            rel: $this.children('option').eq(i).val()
                        }).appendTo($list);
                    }
            }

            // Cache the list items
            var $listItems = $list.children('li');

            // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
            $styledSelect.click(function (e) {
                e.stopPropagation();
                if($('.styledSelect').hasClass('active')) {
                    $('.styledSelect').removeClass('active').next('ul.options').hide();
                } else {
                    $('.styledSelect').addClass('active').next('ul.options').show();
                }
            });

            // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
            // Updates the select element to have the value of the equivalent option

            $listItems.click(function (e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                var rel = $(this).attr('rel');
                if(rel === 'אחר') {
                    $(this).parents('.select').next('input').show().focus();
                } else {
                    $(this).parents('.select').next('input').hide();
                }
                $(this).parent('ul').find('li').show();
                $(this).hide();
                $this.val(rel);
                $list.hide();
                if(! $('body').hasClass('page-template-template-contactus-php')) {
                    window.location.href = document.location.origin+'/'+rel;
                }
            });

            // Hides the unordered list when clicking outside of it
            $(document).click(function () {
                $styledSelect.removeClass('active');
                $list.hide();
            });

        });

        $('#scroll-top img').on('click', function() {
            $('body, html').animate({ scrollTop: 0 }, 800);
        });

        $('.open-modal').on('click', function(e) {
            e.preventDefault();
            var post_title = $(this).attr('data-title');
            var post_content = $(this).parents('.inner-container').find('.press-content').html();
            $('#press-modal').find('h3.title').text(post_title);
            $('#press-modal').find('.post-content').html(post_content);
            $('#press-modal').modal('show');
            return false;
        });

        $('#course-button-link').on('click', function(e) {
            e.preventDefault();
            $('#course-form-modal').modal('show');
						//var btn_link = $(this).attr('href');
						 //window.open(btn_link, '_blank');
            return false;
        });
        $('.open-form').on('click', function(e) {
            e.preventDefault();
            var title = $(this).attr('title');
            $('#form-modal').find('h3.title').text(title);
            $('#form-modal').find('#field_form_title').val(title);
                $.ajax({

                    type:'POST',url:pw_script_vars.ajaxurl,dataType:'html',

                    data:{action:'frm_entries_edit_entry_ajax', post_id:0, entry_id:0, id:6},

                    success:function(html){
                        $('#form-modal').find('.post-content').html(html);
                        $('#form-modal').find('.post-content').find('#field_form_title').val(title);
                        $('#form-modal').modal('show');
                    },

                    error: function (xhr, ajaxOptions, thrownError) {

                        alert(xhr.status);

                        alert(thrownError);

                    }

            });

            return false;
        });

        $("#accordion").on("hide.bs.collapse", function(){
            $('.panel-title.in').removeClass('in');
        });

        jQuery("#accordion").on("shown.bs.collapse", function(e){
            jQuery(e.target).parents('.panel').find('.panel-title').addClass('in');
        });

//        if(jQuery('window').width() >= 768) {

            function addcounterAnimation() {
                var hT = jQuery('#counter-container').offset().top,
                hH = jQuery('#counter-container').outerHeight(),
                wH = jQuery(window).height(),
                wS = jQuery(this).scrollTop();
                if (wS > (hT+hH-wH)){
                    jQuery('#counter-container .counter-inner-container .inner-container').each(function() {
                        var total_items = jQuery(this).find('.counter_holder').children().length;
                        var holder_width = 28*total_items;
                        jQuery(this).find('.counter_holder').css('width', holder_width);
                        jQuery(this).find('.counter_holder .counter_item').each(function() {
                            var number = jQuery(this).attr('data-number');
                            for(var i=0; i<=number;i++) {
                                jQuery(this).append('<span class="d'+i+'">'+i+'</span>');
                            }
                            var height = parseInt(number)+1;
                            jQuery(this).css('height', height*102);
                            jQuery(this).animate({
                                'top':'-'+number*102+'px'
                            }, 4000);
                        });
                    });
                }
            }

            if(jQuery('body').find('#counter-container').length > 0) {
                addcounterAnimation();
            }
//        }
            jQuery(window).scroll(function() {
                if(jQuery('body').find('#counter-container').length > 0) {
                    addcounterAnimation();
                }
            });


            function responder_validation(form) {
                var inputs = form.getElementsByTagName('input');
                var selects = form.getElementsByTagName('select');
                var filter_email = /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z0-9])\.)+[a-z]{2,})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
                var filter_phone = /^(?:|(?:050|052|053|054|055|057|058|02|03|04|08|09)\d{7,7})$/;

                for (a=0; a<inputs.length; a++) {
                    switch (inputs[a].name) {
                        case 'fields[subscribers_email]':
                            if (!filter_email.test(inputs[a].value)) {
                                alert('Email is not valid');
                                inputs[a].focus();
                                return false;
                            }

                            break;
                        case 'fields[subscribers_phone]':
                            var phone = inputs[a].value.replace(/-|\s/g, '');
                            if (!filter_phone.test(phone.length===0 || phone.charAt(0)==='0' ? phone : '0' + phone)) {
                                alert('Phone number is not valid');
                                inputs[a].focus();
                                return false;
                            }

                            break;
                    }
                }

                for (a=0; a<selects.length; a++) {
                        switch (selects[a].name) {
                        }
                }

                if (document.charset) {
                    form.encoding.value = document.charset;
                } else if (document.defaultCharset) {
                    form.encoding.value = document.defaultCharset;
                } else if (document.characterSet) {
                    form.encoding.value = document.characterSet;
                }

                if (navigator.userAgent.indexOf(';FBAV/') > -1) {
                        form.target = '_self';
                }

                return true;
        }

        function getParameterByName( name ){
            var regexS = "[\\?&]"+name+"=([^&#]*)",
          regex = new RegExp( regexS ),
          results = regex.exec( window.location.search );
          if( results == null ){
            return "";
          } else{
            return decodeURIComponent(results[1].replace(/\+/g, " "));
          }
        }

        jQuery('form.newsletter-form').on('submit', function(e) {
            e.preventDefault();

            var form = jQuery(this);
            var listId = jQuery(this).attr('data-list-id');
            var page_name = jQuery(this).attr('data-page-name');
            var formData = form.serialize();
            responder_validation(form[0]);
            jQuery.ajax({
                type: 'POST',
                url: pw_script_vars.ajaxurl,
                async: false,
                dataType: 'json',
                cache:false,
                data: {
                    action: 'submit_responder_form',
                    formData: formData,
                    listID: listId
                },
                success: function (data) {
                    if(data.flag) {
                        $(form).find('.error').hide();

                        if(page_name === 'single-course') {
                            if($('body').hasClass('single-course')) {
                                $('.course-form-container form').hide();
                                $('.course-form-container .success-message').show();
                            } else {
                                if($(form).find('.success').length > 0) {
                                    $(form).find('.success').html(data.data);
                                    $(form).find('.success').show();
                                } else {
                                    form.prepend('<div class="success">'+data.data+'</div>');
                                }
                            }
                        } else {
                            window.location.href = document.location.origin+"/newsletter-signup";
                        }
                    } else {
                        $(form).find('.success').hide();
                        if($(form).find('.error').length > 0) {
                            $(form).find('.error').html(data.data);
                            $(form).find('.error').show();
                        } else {
                            form.prepend('<div class="error">'+data.data+'</div>');
                        }
                    }
                }
            });
        });

        jQuery('#header-menu ul li.menu-item-has-children>a').on('click', function(e) {
            e.preventDefault();
            jQuery(this).toggleClass('in');
            jQuery(this).parent().find('ul.sub-menu').toggle();
            return false;
        });
        var valid_name = jQuery('body').find('.comment-form-author input#author').attr('data-required-text');
        var valid_email = jQuery('body').find('.comment-form-email input#email').attr('data-required-text');
        var valid_comment = jQuery('body').find('.comment-form-comment #comment').attr('data-required-text');
        $('#commentform').validate({

            onfocusout: function(element) {
              this.element(element);
            },

            rules: {
              author: {
                required: true,
                minlength: 2
              },

              email: {
                required: true,
                email: true
              },
              comment: {
                required: true,
                minlength: 2
              }
            },

            messages: {
              author: valid_name,
              email: valid_email,
              comment: valid_comment
            },

            errorElement: "div",
            errorPlacement: function(error, element) {
              element.after(error);
            }
        });

        function scrollendHandler() {
            $('header.banner').removeClass('non-sticky').addClass('sticky');
        }

        function scrollremoveHandler() {
            $('header.banner').removeClass('non-sticky').removeClass('sticky');
        }

        var scrollTimeout = null;
        $(window).on('scroll', function() {
            if(jQuery(window).width() < 768 && (!$('#header-menu').hasClass("in"))) {
                if($(this).scrollTop() === 0) {
//                    $('header.banner').removeClass('non-sticky').removeClass('sticky');
                    $('#scroll-top').removeClass('in');
                    scrollTimeout = setTimeout(function(){scrollremoveHandler();},500);
                } else {
                    $('header.banner').addClass('non-sticky');
                    $('#scroll-top').addClass('in');
                    $('header.banner').addClass('non-sticky');
//                    $('header.banner').removeClass('non-sticky').addClass('sticky');
                    scrollTimeout = setTimeout(function(){scrollendHandler();},500);
                }
            }
        });

        if(jQuery(window).width() <= 768) {
            $('#homepage-slider').carousel({
                interval: 5000
            });
        }

        $('.navbar-collapse').on('shown.bs.collapse', function() {
            $('body').css('overflow', 'hidden');
        });

        $('.navbar-collapse').on('hidden.bs.collapse', function() {
            $('body').css('overflow', 'auto');
        });


        var ref_course = getParameterByName('ref-course');
        if(ref_course === 'course2' || ref_course === 'course2/' || ref_course === 'online-courses' || ref_course === 'online-courses/') {
            $('header nav ul li.course2').addClass('current-menu-item');
        } else if(ref_course === 'community-courses' || ref_course === 'community-courses/') {
            $('header nav ul li.community-courses').addClass('current-menu-item');
        }

        if($('body').hasClass('single-post')) {
            $('header nav ul li.blog-category').addClass('current-menu-item');
        } else if ($('body').hasClass('tax-faqs-category')) {
            $('header nav ul li.template-faqs-php').addClass('current-menu-item');
        } else if ($('body').hasClass('page-template-template-press')) {
            $('header nav ul li.template-press-php').addClass('current-menu-item');
        } else if($('body').hasClass('category')) {
            $('header nav ul li.blog-category').addClass('current-menu-item');
        } else if($('body').hasClass('tax-hubbloggers-category')) {
            $('header nav ul li.template-hubbloggers-php').addClass('current-menu-item');
        } else if($('body').hasClass('single-hubbloggers')) {
            $('header nav ul li.template-hubbloggers-php').addClass('current-menu-item');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
				$frm_msg_length  = $('#course-form-modal .frm_error_style').length ;
				$frm_succ_msg_length  = $('#course-form-modal .frm_message').length ;
				$link  = $('#course-button-link').attr('href') ;

				if($frm_msg_length){
					$('#course-form-modal').modal('show');
				$('#course-form-modal .frm_error_style').show();
				}
				// reload the page

				if($frm_succ_msg_length){
					$('#course-form-modal').modal('show');
						$('#course-form-modal .frm_error_style').show();

					$('#course-form-modal .title, #course-form-modal .inst').hide();

						window.open($link, '_blank');

				}


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'date': {
      init: function() {
        // JavaScript to be fired on the archive page
        $('header nav ul li.menu-item-object-category').addClass('current-menu-item');
      }
    },
    'category': {
        init: function() {
            // JavaScript to be fired on the archive page
            $('header nav ul li.menu-item-object-category').addClass('current-menu-item');
        }
    },
    'single': {
        init: function() {
            function PopupCenter(url, title, w, h) {
                // Fixes dual-screen position                         Most browsers      Firefox
                var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
                var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

                var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                var left = ((width / 2) - (w / 2)) + dualScreenLeft;
                var top = ((height / 2) - (h / 2)) + dualScreenTop;
                var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

                // Puts focus on the newWindow
                if (window.focus) {
                    newWindow.focus();
                }
            }

            jQuery('#social-share-container a.open-window').on('click', function(e) {
                e.preventDefault();
                var url = jQuery(this).attr('href');
                new PopupCenter(url, 'newWin', '500', '400');
                return false;
            });
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
